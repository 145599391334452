import styled from 'styled-components';
import colors from '../../UI/Colors/index';

export const Container = styled.section`
  //margin-top: 70px;
  padding: 100px 60px;
  background: ${colors.white};
  @media (max-width: 1000px) {
    padding: 80px 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 120vw;
  margin: 0 auto;
  //background: red;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  //margin: 0px 50px;
  padding-top: 15px;
  margin-top: 30px;
  margin-left: 70px;
  //background: green;
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

export const Row = styled.div`
  //background: pink;
  margin-top: 40px;
  //padding-left: 1px;
  margin-left: 1px;
  //margin: 40px auto 0px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Link = styled.a`
  //margin-bottom: 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  //line-height: 16px;
  color: #333333;
  text-decoration: underline;
  margin-top: -10px;
  &:hover {
    color: #9b9999;
    transition: 200ms ease-in;
  }
`;

export const TitleSection = styled.h2`
  //margin-bottom: 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 115%;
  text-align: justify;
  text-transform: uppercase;
  color: #4070ff;
  padding-left: 40px;
  @media (max-width: 600px) {
    padding-left: 10px;
  }
`;

export const Description = styled.h4`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 115%;
  text-align: center;
  text-transform: capitalize;
  margin-top: 15px;
  color: #333333;
`;

export const SubDescription = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 115%
  text-align: center;
  text-transform: capitalize;
  color: #333333;
`;

export const Line = styled.div`
  width: 200px;
  height: 6px;
  background: #4070ff;
  border-radius: 10px;
  //margin-top: 5px;
  //margin: 0px auto;
  margin-left: 40px;
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`;
