import React from 'react';
import Banner from '../../Components/Banner';
import Contact from '../../Components/ContactUs/index';
import Team from '../../Components/Team';
import Project from '../../Components/Project';
import WhoWeAre from '../../Components/WhoWeAre';

// import { Container } from './styles';

//import Patterns from '../../Patterns/index';
//import imgSectionGirl from '../../assets/Elemento SIMEPS 1.svg';
//import imgGrup from '../../assets/casa simeps 1.svg';

function Home() {
  return (
    <>
      <Banner />
      <WhoWeAre />
      <Project />
      <Team />
      <Contact />
      {/**
     * 
     * 
      <Patterns id="somos" title="Quem Somos" imgSectionleft={imgSectionGirl} />
      <Patterns
        id="projetos"
        title="Nosso Projeto"
        sizeBigLeftDiv="true"
        imgSectionRight={imgGrup}
        description="texto aq"
        subdescription="textao aqui"
      />
    */}
    </>
  );
}

export default Home;
