import styled, { keyframes } from 'styled-components';
import colors from '../../UI/Colors/index';

export const Container = styled.footer`
  margin-top: 1px;
  padding: 40px 60px;
  background: ${colors.blue_footer};
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 30vw;
  margin: 0 auto;
  //background: red;
   @media (max-width: 800px) {
      margin: 0;
       max-width: 80vw;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 1px;
  //background: gray;
`;

export const ColumnSocial = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 0px 30px;
  margin: 0px auto;
  //background: green;
  > a {
    padding: 0px 10px;
    @media (max-width: 800px) {
      margin: 20px auto;
    }
  }
`;

export const Line = styled.div`
  width: 217px;
  height: 6px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0px auto;
`;

export const Circle = styled.div`
  width: 34px;
  height: 34px;
  text-align: center;
  padding-top: 5px;
  background: #ffffff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
`;

export const Row = styled.div`
  /*background: pink; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

const rotating = keyframes`
     0% { transform:scale(1.2) }
     75% { transform:scale(1.2) }
     100% { transform:scale(1.2) }
   `;

export const Link = styled.a`
  color: #fff;
  margin-bottom: 20px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    //transition: all 0.5s;
    animation: ${rotating} 1s linear infinite;
  }
`;

export const DescriptionFooter = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  //line-height: 149.3%;
  color: #ffffff;
`;
