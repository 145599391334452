import React from 'react';
import {
  Container,
  Wrapper,
  Row,
  ColumnLeft,
  //ColumnRight,
  Title,
} from './styles';

import ImgContact from '../../assets/grup_banner.svg';
import ImgSrc from '../../assets/cidade 1.svg';

import { Typewriter } from 'react-typewriting-effect';
import 'react-typewriting-effect/dist/index.css';

export default function Banner() {
  return (
    <Container id="home">
      <Wrapper>
        <Row>
          <ColumnLeft>
            <Title>
              <Typewriter
                string="Soluções tecnológicas para o cotidiano."
                delay={80}
                stopBlinkinOnComplete
                cursor=""
              ></Typewriter>
            </Title>
          </ColumnLeft>
          <ColumnLeft>
            <img alt="contact" src={ImgContact} />
          </ColumnLeft>
        </Row>
      </Wrapper>
      <img src={ImgSrc} alt="cidade" />
    </Container>
  );
}
