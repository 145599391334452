import styled from 'styled-components';
import colors from './Colors';

export const DivButton = styled.div`
  //display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.select ? '3px solid ' + colors.blue_footer : 'none'};
  //margin-left: 0px;
  margin-right: 1px;
`;

export const ButtonHeader = styled.button`
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 115.3%;
  text-align: justify;
  color: #383838;
  &:hover {
    opacity: 0.8;
    //background-color: ${colors.white};
  }
  &:focus {
    //outline: 0;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
