import React from 'react';
import {
  Container,
  Wrapper,
  Row,
  Column,
  TitleSection,
  Line,
  Link,
  Description,
  SubDescription,
  //ColumnImg,
  //ColumnSocial,
} from './styles';

import andre from '../../assets/Team/Andre 1.svg';
import bruno from '../../assets/Team/bruno 1.svg';
import larissa from '../../assets/Team/Larissa.svg';
import joao from '../../assets/Team/joao.svg';

export default function Team() {
  return (
    <Container id="equipe">
      <Wrapper>
        <TitleSection>Nossa Equipe</TitleSection>
        <Line />
        <Row>
          <Column>
            <img alt="Larissa Milena" src={larissa} />
            <Description>Larissa milena</Description>
            <SubDescription>Dev. Front-end</SubDescription>
          </Column>
          <Column>
            <img alt="Bruno José" src={bruno} />
            <Description>Bruno José</Description>
            <SubDescription>Dev. Front-end</SubDescription>
            <Link href="https://brunojose.herokuapp.com/">
              {'   '}
              Portifólio {' >'}
            </Link>
          </Column>
          <Column>
            <img alt="André Lopes" src={andre} />
            <Description>André Lopes</Description>
            <SubDescription>Dev. Full stack</SubDescription>
            <Link href="https://andrelopes.herokuapp.com/">
              {'   '}
              Portifólio {' >'}
            </Link>
          </Column>
          <Column>
            <img alt="João Victor" src={joao} />
            <Description>João Victor</Description>
            <SubDescription>UX/UI Design</SubDescription>
            <Link href="https://jvqueiroz.github.io/">
              {'   '}
              Portifólio {' >'}
            </Link>
          </Column>
        </Row>
      </Wrapper>
    </Container>
  );
}
