export default {
  blue_primary: '#06608A',
  blue_secondary: '#4C8DAA',
  blue_ternary: '#4070FF',
  blue_footer: '#003B5B;',
  gray_primary: '#F8F8F8',
  gray_secondary: '#E5E5E5',
  white: '#FFFFFF',
  dark: '#383838',
  danger: '#C20C0d',
  green: '#59C491',
  yellow: '#ECD768',
};
