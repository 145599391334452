import styled, { keyframes } from 'styled-components';
import colors from '../../UI/Colors/index';
import ImgSrc from '../../assets/casa simeps 1.svg';

export const Container = styled.section`
  padding: 40px 0px;
  background-color: ${colors.gray_primary};
  //background-image: url(${ImgSrc});
  @media (max-width: 800px) {
    padding: 70px 30px;
  }
  > img {
    width: 98.5vw;
    margin-top: -250px;
    @media (max-width: 1000px) {
      margin-top: -200px;
      width: 98vw;
      margin-left: -27px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  margin: 0 auto;
  //background: blue;
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
  //background: red;
  > img {
    margin-top: 100px;
    //margin-bottom: 50px;
    z-index: 1;
  }
  @media (max-width: 600px) {
    margin-left: 0px;
    width: 100%;
    > img {
      margin-top: 0px;
      margin-left: 0px;
      padding-left: 0px;
    }
  }
`;

export const Row = styled.div`
  //background: pink;
  display: grid;
  grid-template-columns: 60vw 30vw;
  grid-gap: 20px;
  padding-left: 30px;
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media (max-width: 600px) {
    padding-left: 5px;
  }
`;

export const Link = styled.a`
  width: 233px;
  height: 45px;

  background: #1e88e5;
  border-radius: 15px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 149.3%;
  color: #ffffff;
  padding-top: 5px;
  text-decoration: none;
  &:hover {
    color: #9b9999;
    transition: 200ms ease-in;
  }
`;

const cursor = keyframes`
0%, 100%{
  opacity: 0;
}
50% {
  opacity: 1;
}
`;

const typing = keyframes`
0%, 50%{
  width: 0;
}
100% {
  width: 20px;
}
`;

export const Title = styled.h1`
  margin-top: 90px;
  margin-left: 60px;
  padding-bottom: 110px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 115.3%;
  text-align: left;
  color: #06608a;
  &:after {
    content: '|';
    margin-left: 3px;
    opacity: 1;
    animation: ${cursor} 0.7s infinite, ${typing} 4s 1s normal;
  }

  @media (max-width: 1200px) {
    font-size: 52px;
  }

  @media (max-width: 800px) {
    font-size: 54px;
    margin-left: 0px;
    margin-top: 45px;
  }
  @media (max-width: 600px) {
    margin-top: 45px;
    padding-bottom: 15px;
    font-size: 44px;
    margin-left: 0px;
  }
`;

export const SubTitle = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  //line-height: 115.3%;
  text-align: left;
  text-transform: capitalize;
  margin-top: 30px;
  margin-bottom: -10px;
  color: #333333;
`;
