import React from 'react';
import {
  Container,
  Wrapper,
  Row,
  ColumnLeft,
  //ColumnRight,
  Link,
  TitleSection,
  Description,
  Line,
} from './styles';

import ImgContact from '../../assets/Black Man Working on Analytics 1.svg';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

export default function Contact() {
  return (
    <Container id="contato">
      <TitleSection>Fale Conosco</TitleSection>
      <Line />
      <Wrapper>
        <Row>
          <ColumnLeft>
            <img alt="contact" src={ImgContact} />
          </ColumnLeft>
          <ColumnLeft>
            <Description>
              E aí, já pensou em resolver seus problemas com apenas alguns
              cliques?
            </Description>
            <Link href="https://api.whatsapp.com/send?phone=+5588996351705">
              <WhatsAppIcon style={{ paddingBottom: '4px' }} />
              Fale conosco{' '}
            </Link>
          </ColumnLeft>
        </Row>
      </Wrapper>
    </Container>
  );
}
