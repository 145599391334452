import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { ButtonHeader, DivButton } from '../../UI/styles';

import logo from '../../assets/logo.svg';
import simeps from '../../assets/simeps.svg';
import './styles/css/index.css';

function Header() {
  const [select, setSelect] = useState('0');

  function handleClick(value, event) {
    setSelect(value);
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        fixed="top"
        className="border"
      >
        <Navbar.Brand href="#home" className="logo">
          <img
            src={logo}
            width="35"
            height="30"
            className="d-inline-block align-top"
            alt="logo"
          />
          <img
            src={simeps}
            width="120"
            height="30"
            className="d-inline-block align-top"
            alt="simeps"
            onClick={(event) => {
              handleClick('0', event);
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="link text-brom">
            <Nav.Link
              href="#somos"
              onClick={(event) => {
                handleClick('1', event);
              }}
            >
              <DivButton select={select === '1'}>
                <ButtonHeader type="submit">Quem somos</ButtonHeader>
              </DivButton>
            </Nav.Link>
            <Nav.Link
              href="#projetos"
              onClick={(event) => {
                handleClick('2', event);
              }}
            >
              <DivButton select={select === '2'}>
                <ButtonHeader type="submit">Nossos Projetos</ButtonHeader>
              </DivButton>
            </Nav.Link>
            <Nav.Link
              href="#equipe"
              onClick={(event) => {
                handleClick('3', event);
              }}
            >
              <DivButton select={select === '3'}>
                <ButtonHeader type="submit">Nossa equipe</ButtonHeader>
              </DivButton>
            </Nav.Link>
            <Nav.Link
              href="#contato"
              onClick={(event) => {
                handleClick('4', event);
              }}
            >
              <DivButton select={select === '4'}>
                <ButtonHeader type="submit">Fale Conosco</ButtonHeader>
              </DivButton>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Header;
