import React from 'react';
import {
  Container,
  Wrapper,
  Row,
  ColumnLeft,
  //ColumnRight,
  TitleSection,
  Description,
  SubDescription,
  Line,
  //ColumnImg,
  //ColumnSocial,
} from './styles';

import ImgContact from '../../assets/Elemento SIMEPS 1.svg';

export default function WhoWeAre() {
  return (
    <Container id="somos">
      <TitleSection>Quem Somos</TitleSection>
      <Line />
      <Wrapper>
        <Row>
          <ColumnLeft animation="true">
            <img alt="contact" src={ImgContact} />
          </ColumnLeft>
          <ColumnLeft>
            <Description>Olá,</Description>
            <Description>nós somos a SIMEPS!</Description>
            <SubDescription>
              Uma startup com a missão de ajudar pessoas, proporcionando a
              melhor das experiências em atividades do cotidiano. Oferecemos as
              melhores soluções assegurando comodidade e agilidade em processos
              que antes poderiam ser burocráticos.
            </SubDescription>
          </ColumnLeft>
        </Row>
      </Wrapper>
    </Container>
  );
}
