import React from 'react';
import {
  Container,
  Wrapper,
  Row,
  ColumnLeft,
  //ColumnRight,
  Link,
  TitleSection,
  SubTitle,
  Description,
  Line,
  //ColumnImg,
  //ColumnSocial,
} from './styles';

import ImgContact from '../../assets/casa simeps 1.svg';

export default function Project() {
  return (
    <Container id="projetos">
      <TitleSection>Nosso Projeto</TitleSection>
      <Line />
      <Wrapper>
        <Row>
          <ColumnLeft>
            <SubTitle>Mattes</SubTitle>
            <Description>
              O mattes é uma plataforma online que possibilita o encontro de
              pessoas que estejam buscando ou anunciando imóveis para alugar, ou
              até mesmo moradias compartilhadas. Sejam casas, apartamentos,
              quartos, entre outros tipos. Em poucos passos você encontrará o
              lugar/ou colega ideal de forma simples, prática, com transparência
              e comunicatividade.
            </Description>
            <Link href="/default">Acessar</Link>
          </ColumnLeft>
          <ColumnLeft>
            <img alt="contact" src={ImgContact} />
          </ColumnLeft>
        </Row>
      </Wrapper>
    </Container>
  );
}
