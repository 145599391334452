import React from 'react';
import {
  Container,
  Wrapper,
  Row,
  ColumnLeft,
  ColumnRight,
  Title,
  Link,
  LinkText,
  Description,
} from './styles';

//import ImgContact from '../../assets/grup_banner.svg';
import ImgSrc from '../../assets/cidade 1.svg';
//import logo from '../../assets/logo.svg';
import simeps from '../../assets/simeps.svg';

const Default = () => {
  return (
    <Container id="home">
      <Wrapper>
        <img src={simeps} alt="logo" />
        <Row>
          <ColumnLeft>
            <Title>Página em construção</Title>
            <Description>
              No momento está página encontra-se em contrução, mas estamos
              trabalhando para tornar nosso site ainda melhor para você.
              Enquanto isso, sigam
              <LinkText href="https://www.instagram.com/simeps_">
                {' '}
                @simeps_{' '}
              </LinkText>
              no instagram.
            </Description>
            <Link href="/">Voltar ao site</Link>
          </ColumnLeft>
          <ColumnRight></ColumnRight>
        </Row>
      </Wrapper>
      <img src={ImgSrc} alt="cidade" />
    </Container>
  );
};

export default Default;
