import styled, { keyframes } from 'styled-components';
import colors from '../../UI/Colors/index';

const pulso = keyframes`
    0% {transform: scale(1);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1);}
    `;

const pulsoMobile = keyframes`
    0% {transform: scale(0.8);}
    50% {transform: scale(1);}
    100% {transform: scale(0.8);}
    `;

export const Container = styled.section`
  //margin-top: 1px;
  //padding-bottom: 40px;
  padding: 100px 60px;
  background: ${colors.blue_secondary};
  @media (max-width: 1000px) {
    padding: 80px 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  margin: 0 auto;
  //background: blue;
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  //animation: ${pulso} 5s linear infinite;
  //background: red;
  @media (max-width: 600px) {
    > img {
      width: 90%;
      margin-left: 0px;
      //animation: ${pulsoMobile} 5s linear infinite;
    }
  }
`;

export const Description = styled.h2`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 110%;
  text-align: left;
  padding-left: 80px;
  //background-color: red;
  color: ${colors.white};
  @media (max-width: 800px) {
    font-size: 44px;
  }
  @media (max-width: 600px) {
    font-size: 44px;
    padding-left: 10px;
    padding-right: 0px;
  }
`;

export const SubDescription = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 149.3%;
  padding-left: 80px;
  padding-right: 30px;
  color: ${colors.white};
  text-align: left;
  @media (max-width: 800px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 0px;
  }
`;
export const Row = styled.div`
  //background: pink;
  display: grid;
  grid-template-columns: 30vw 54vw;
  grid-gap: 35px;
  padding-left: 40px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media (max-width: 600px) {
    padding-left: 0px;
  }
`;

export const Link = styled.a`
  width: 233px;
  height: 45px;

  background: #1e88e5;
  border-radius: 15px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 149.3%;
  color: #ffffff;
  padding-top: 5px;
  text-decoration: none;
  &:hover {
    color: #9b9999;
    transition: 200ms ease-in;
  }
`;

export const TitleSection = styled.h4`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 115%;
  text-align: justify;
  text-transform: uppercase;
  margin-left: 40px;
  color: ${colors.white};

  @media (max-width: 600px) {
    margin-left: 10px;
  }
`;

export const Line = styled.div`
  width: 200px;
  height: 6px;
  background: ${colors.white};
  border-radius: 10px;
  margin-bottom: 50px;
  //margin: 0px auto;
  margin-left: 40px;
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`;
