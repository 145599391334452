import React from 'react';
import { Route } from 'react-router-dom';
// import { Container } from './styles';

import Home from '../Pages/Home/index';
import Construction from '../Pages/Construction/index';

import Header from '../Components/header/index';
import Footer from '../Components/Footer';

function Routes() {
  return (
    <>
      <Route path="/" exact={true}>
        <Header />
        <Home />
        <Footer />
      </Route>

      <Route path="/default">
        <Construction />
      </Route>
    </>
  );
}

export default Routes;
