import React from 'react';
import {
  Container,
  Wrapper,
  Row,
  Column,
  Link,
  DescriptionFooter,
  ColumnSocial,
  Line,
  Circle,
} from './styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

export default function Footer() {
  return (
    <Container>
      <Wrapper>
        <Row>
          <ColumnSocial>
            <Link href="https://www.facebook.com/simeps">
              <Circle>
                <FacebookIcon style={{ color: '#003B5B' }} />
              </Circle>
            </Link>
            <Link href="https://www.instagram.com/simeps_">
              <Circle>
                <InstagramIcon
                  style={{ color: '#003B5B', background: '#ffffff' }}
                />
              </Circle>
            </Link>
          </ColumnSocial>
        </Row>
        <Row>
          <Column>
            <DescriptionFooter>
              SIMEPS 2020 - Todos os direitos reservados
            </DescriptionFooter>
            <Line />
          </Column>
        </Row>
      </Wrapper>
    </Container>
  );
}
