import styled from 'styled-components';
import colors from '../../UI/Colors/index';
import ImgSrc from '../../assets/casa simeps 1.svg';

export const Container = styled.footer`
  //margin-top: 70px;
  padding: 40px 0px;
  background-color: ${colors.gray_primary};
  //background-image: url(${ImgSrc});
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
  > img {
    width: 98.5vw;
    margin-top: -10px;
    @media (max-width: 1000px) {
      width: 95vw;
      margin-left: -27px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  margin: 0 auto;
  //background: blue;
  > img {
    width: 10%;
    margin-top: -25px;
    margin-left: 90px;
    align-items: left;
    //background: blue;
    @media (max-width: 600px) {
      width: 50%;
      margin-left: 30px;
    }
  }
`;

export const ColumnLeft = styled.div`
  width: 60vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 30px;
  //background: red;
  @media (max-width: 800px) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const ColumnRight = styled.div`
  width: 30vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 580px;
  margin-top: 85px;
  margin-bottom: 80px;
  z-index: 1;
  //background: green;
  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
  @media (min-width: 1024px) {
    > img {
      width: 460px;
      margin-top: 80px;
    }
  }
`;

export const Description = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 149.3%;
  text-align: left;
  color: #333333;
  margin-left: 60px;
  @media (max-width: 600px) {
    margin-left: 22px;
  }
`;

export const Row = styled.div`
  //background: pink;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Link = styled.a`
  width: 233px;
  height: 45px;
  margin-left: 60px;
  background: #1e88e5;
  border-radius: 15px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 149.3%;
  color: #ffffff;
  padding-top: 5px;
  text-decoration: none;
  &:hover {
    color: #9b9999;
    background: ${colors.blue_footer}
    transition: 200ms ease-in;
    text-decoration: none;
  }
  media(max-width: 600px) {
    margin-left: 0px;
  }
`;

export const LinkText = styled.a`
  width: 233px;
  height: 45px;
  //margin-left: 60px;
  //background: #1e88e5;
  border-radius: 15px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 149.3%;
  color: #1e88e5;
  //padding-top: 5px;
  text-decoration: none;
`;

export const Title = styled.p`
  margin-top: 70px;
  margin-left: 60px;
  //margin-bottom: 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 115.3%;
  text-align: left;
  color: #06608a;
  @media (max-width: 800px) {
    font-size: 54px;
    margin-left: 0px;
    margin-top: 60px;
  }
  @media (max-width: 600px) {
    font-size: 44px;
    margin-left: 12px;
  }
`;

export const SubTitle = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  //line-height: 115.3%;
  text-align: left;
  text-transform: capitalize;
  margin-top: 30px;
  margin-bottom: 80px;
  color: #333333;
`;
